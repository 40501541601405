<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-tr>
                                        <b-th>{{$t('globalTrans.division')}}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.division : distributionItem.division_bn }} </b-td>
                                        <b-th>{{$t('globalTrans.district')}}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.district : distributionItem.district_bn }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{$t('org_pro_upazilla.upazilla')}}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.upazilla : distributionItem.upazilla_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                        <b-td> {{ locale === 'en' ? fiscalYear.text : fiscalYear.text }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.circular_name') }}</b-th>
                                        <b-td colspan="3"> {{ locale === 'en' ? distributionItem.circular_name : distributionItem.circular_name_bn }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.place') }}</b-th>
                                        <b-td> {{ locale === 'en' ? distributionItem.place : distributionItem.place_bn }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.distribution_data') }}</b-th>
                                        <b-td>{{ distributionItem.distribution_date | dateFormat }}</b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                            <div class="col-sm-12 col-md-12">
                                <h4>{{ $t('dae_subsidy.farmer_list') }}</h4>
                                <b-table-simple striped bordered small class="mt-2">
                                    <b-thead>
                                        <b-tr class="text-center">
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('dae_subsidy.farmer_name') }}</b-th>
                                            <b-th>{{ $t('financialAid.farmer_father_name') }}</b-th>
                                            <b-th>{{ $t('dae_subsidy.nid') }}</b-th>
                                            <b-th>{{ $t('dae_subsidy.mobile_no') }}</b-th>
                                            <b-th>{{$t('common_config.account_type')}}</b-th>
                                            <b-th>{{$t('common_config.account_no')}}</b-th>
                                            <b-th>{{$t('bsri_demandDIS.bank')}}</b-th>
                                            <b-th>{{$t('bsri_demandDIS.branch')}}</b-th>
                                            <b-th>{{$t('dae_subsidy.routing')}}</b-th>
                                            <b-th class="text-right">{{$t('dae_subsidy.cashAllocationTk')}}</b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="(detail,index) in applicant_details" :key="index" class="text-center">
                                            <b-td>{{ $n(index+1) }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_data.name_bn : detail.farmer_data.name }}</b-td>
                                            <b-td>{{ ($i18n.locale === 'bn') ? detail.farmer_data.father_name_bn : detail.farmer_data.father_name }}</b-td>
                                            <b-td>{{ $n(detail.farmer_data.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-td>{{ detail.farmer_data.mobile_no | mobileNumber }}</b-td>
                                            <b-td>{{ detail.account_type_name }}</b-td>
                                            <b-td>{{ detail.farmer_data.ac_category_id !== 1 ? $n(parseInt(detail.farmer_data.account_no), { useGrouping: false }) : (detail.farmer_data.wallet_no) ? ($i18n.locale === 'bn' ? '০' : '0') + $n(detail.farmer_data.wallet_no, { useGrouping: false }) : ' ' }}</b-td>
                                            <b-td>{{ detail.farmer_data.ac_category_id === 1 ? detail.ac_type_name : detail.bank_name }}</b-td>
                                            <b-td>{{ detail.branch_name ? detail.branch_name : ' ' }}</b-td>
                                            <b-td>{{ detail.routing_no ? detail.routing_no : ' ' }}</b-td>
                                            <b-td class="text-right">{{ $n(allocationAmount, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                    <b-tfoot style="font-weight:bold">
                                        <b-tr>
                                            <b-td colspan="10" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                            <b-td class="text-right">{{ $n(grandTotal, { useGrouping: false }) }}</b-td>
                                        </b-tr>
                                    </b-tfoot>
                                </b-table-simple>
                            </div>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { getDistApplicantApi } from '../../api/routes'
import ExportPdf from './export-pdf_details'

export default {
    props: ['itemId'],
    data () {
        return {
            grandTotal: 0,
            allocationAmount: 0,
            applicantList: [],
            loading: false,
            distributionItem: '',
            fiscalYear: '',
            applicant_details: [],
            accountTypeList: [
              { value: 1, text_en: 'Mobile Banking', text_bn: 'মোবাইল ব্যাংকিং' },
              { value: 2, text_en: 'Bank', text_bn: 'ব্যাংক' }
            ]
        }
    },
    created () {
        this.distributionItem = this.getRelatinalData(this.itemId)
        this.distributionDetails(this.itemId)
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        circularTypeList () {
            const data = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন' }
            ]
            return data
        }
    },
    methods: {
        distributionDetails (searchData) {
            this.loading = true
            const params = {
                circular_id: searchData.circular_id,
                division_id: searchData.division_id,
                district_id: searchData.district_id,
                upazilla_id: searchData.upazilla_id
            }
            RestApi.getData(incentiveGrantServiceBaseUrl, getDistApplicantApi, params).then(response => {
                if (response.success) {
                    this.applicant_details = this.getRelationalData(response.data)
                    this.allocationAmount = response.allocationAmount
                    this.grandTotal = parseFloat(response.data.length * response.allocationAmount)
                }
                this.loading = false
            })
        },
        getRelationalData (data) {
            const listData = data.map((item, index) => {
                const deReqObj = {
                    far_general_info_id: item.far_general_info_id,
                    subs_demand_id: item.id,
                    land_quantity: item.land_quantity,
                    farmer_name: item.farmer_data.name,
                    farmer_name_bn: item.farmer_data.name_bn,
                    father_name: item.farmer_data.father_name,
                    father_name_bn: item.farmer_data.father_name_bn,
                    nid_no: item.farmer_data.nid_no,
                    mobile_no: item.farmer_data.mobile_no,
                    demandCollectioCropList: item.demand_collection_details
                }
                const accountTypeData = this.accountTypeList.find(type => type.value === item.farmer_data.ac_category_id)
                const accountGroupData = { account_type_name: accountTypeData !== undefined ? (this.locale === 'bn' ? accountTypeData.text_bn : accountTypeData.text_en) : '' }
                const acTypeName = this.$store.state.commonObj.bankObj.bankList.find(acType => acType.value === item.farmer_data.ac_type_id)
                const acTypeData = { ac_type_name: acTypeName !== undefined ? (this.locale === 'bn' ? acTypeName.text_bn : acTypeName.text_en) : '' }
                const bankObj = this.$store.state.commonObj.bankObj.bankList.find(bank => bank.value === item.farmer_data.bank_id)
                const bankData = { bank_name: bankObj !== undefined ? (this.locale === 'bn' ? bankObj.text_bn : bankObj.text_en) : '' }
                const branchObj = this.$store.state.commonObj.bankObj.branchList.find(branch => branch.value === item.farmer_data.branch_id)
                const branchData = { branch_name: branchObj !== undefined ? (this.locale === 'bn' ? branchObj.text_bn : branchObj.text_en) : '' }
                return Object.assign({}, item, deReqObj, accountGroupData, acTypeData, bankData, branchData)
            })
            return listData
        },
        getCircularType (circularType) {
            if (circularType === 1) {
                return this.$i18n.locale === 'en' ? 'Subsidy' : 'ভর্তুকি'
            } else if (circularType === 2) {
                return this.$i18n.locale === 'en' ? 'Incentive' : 'প্রণোদনা'
            } else if (circularType === 3) {
                return this.$i18n.locale === 'en' ? 'Rehabilitation' : 'পুনর্বাসন'
            }
        },
        getFiscalYear (fiscalyear) {
            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscal => fiscal.value === fiscalyear)
            if (this.$i18n.locale === 'bn') {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text_bn }
            } else {
                return { value: fiscalYearObj.value, text: fiscalYearObj.text }
            }
        },
        getRelatinalData (item) {
            const divisionList = this.$store.state.commonObj.divisionList
            const districtList = this.$store.state.commonObj.districtList
            const upazilaList = this.$store.state.commonObj.upazilaList
            const circularObj = this.$store.state.incentiveGrant.commonObj.circularList.find(circular => circular.value === item.circular_id)
            const divisionObject = divisionList.find(division => division.value === item.division_id)
            const districtObject = districtList.find(district => district.value === item.district_id)
            const upazillaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
            this.fiscalYear = this.getFiscalYear(item.fiscal_year_id)
            const circularData = {
                circular_name: circularObj !== undefined ? circularObj.text_en : '',
                circular_name_bn: circularObj !== undefined ? circularObj.text_bn : ''
            }
            const divisionData = {
                division: divisionObject !== undefined ? divisionObject.text_en : '',
                division_bn: divisionObject !== undefined ? divisionObject.text_bn : ''
            }
            const districtData = {
                district: districtObject !== undefined ? districtObject.text_en : '',
                district_bn: districtObject !== undefined ? districtObject.text_bn : ''
            }
            const upazillaData = {
                upazilla: upazillaObject !== undefined ? upazillaObject.text_en : '',
                upazilla_bn: upazillaObject !== undefined ? upazillaObject.text_bn : ''
            }
            return Object.assign({}, item, circularData, divisionData, districtData, upazillaData)
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Distributed' : 'বন্টন'
            ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 2, reportTitle, this)
        },
        getCircularTypeName (value) {
            const circularList = this.circularTypeList.find(item => item.value === value)
            return circularList.text
        }
    }
}
</script>
